/*
https://splidejs.com/guides/getting-started/
*/

// import '@splidejs/splide/css/core';
// import Splide from '@splidejs/splide';

document.addEventListener( 'DOMContentLoaded', function() {
    const slider = document.querySelector('.slider');

    if( slider ) {
        var splide = new Splide( '.slider' , {
            type: 'fade',
            speed: 1000,
            arrows: false,
            pagination: true,
            rewind: true,
            waitForTransition: false,
        });
        splide.mount();
    }
});