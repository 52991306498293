const body = document.querySelector('body');
const banner = document.querySelector('body > main > .banner');
const noHeroBanner = document.querySelector('body > .main-no-hero > .banner');
const menuTrigger = document.querySelector('button.menu-toggle');

function updateMenuTrigger() {
    if (banner && menuTrigger) {
        const bannerBottomPosition = banner.offsetHeight;
        const noHeroBannerPosition = noHeroBanner ? noHeroBanner.offsetHeight : null;
        
        scrollPosition = window.scrollY;

        if( noHeroBannerPosition ){
            if( scrollPosition > 100 ){
                body.classList.add('js-menu-trigger-is-inverted');
            }else{
                body.classList.remove('js-menu-trigger-is-inverted');
            }
        }else if (scrollPosition > (bannerBottomPosition - menuTrigger.offsetTop - menuTrigger.offsetHeight / 2)) {
            body.classList.add('js-menu-trigger-is-inverted');
        } else {
            body.classList.remove('js-menu-trigger-is-inverted');
        }
    }
}

window.addEventListener('resize', () => {
    updateMenuTrigger();
});

window.addEventListener('scroll', () => {
    updateMenuTrigger();
});