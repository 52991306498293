// swiperjs

// Initializing Swiper
const swiper = new Swiper('.section__reviews--block', {
    slidesPerView: 3,
    initialSlide: 0,
    centeredSlides: true,
    loop: true,
    spaceBetween: 20,
    rewind: true,
    pagination: {
        el: '.swiper-pagination',
        clickable: true,
    },
    breakpoints: {
        100: {
            slidesPerView: 1,
        },
        1024: {
            slidesPerView: 2,
        },
        1200: {
            slidesPerView: 3,
        },
    },
})

const swiper2 = new Swiper('.section__bxa--block', {
    slidesPerView: 1,
    rewind: true,
    navigation: {
        nextEl: ".section__featured--nav-next",
        prevEl: ".section__featured--nav-prev",
    },
    // breakpoints: {
    //     100: {
    //         slidesPerView: 1,
    //     },
    //     1024: {
    //         slidesPerView: 2,
    //     },
    //     1200: {
    //         slidesPerView: 3,
    //     },
    // },
})